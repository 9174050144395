const baseFitmentFields = globalThis.Convermax.config?.baseFitmentFields || ['Year', 'Make', 'Model'];
const extraFitmentFields = globalThis.Convermax.config?.extraFitmentFields || ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal'];

const brandSelectionPageUrl = '/pages/shop-by-brand';
const categorySelectionPageUrl = '/collections';

function getFitmentSearchTitle() {
  const { pageType } = window.ShopifyAnalytics.meta.page;

  const title = document.querySelector('body.templateCollection .wrap-cata-title h2')?.textContent.trim();
  return pageType === 'collection' ? title : pageType === 'vendors' ? `${title} Parts` : 'Parts';
}

export default {
  platform: 'shopify',
  searchPageUrl: '/search',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  product: {
    searchResultsImageWidth: 380,
    searchResultsImageHeight: 380,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      {
        pathname: categorySelectionPageUrl,
        field: window.Convermax.config?.categorySelectionPageField || 'category',
      },
      { pathname: brandSelectionPageUrl, field: 'vendor' },
    ],
    categorySelectionPageRedirect: window.Convermax.config?.categorySelectionPageRedirect ?? true,
    getFitmentSearchTitle,
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: { replace: 'body.templateCollection .page-cata #col-main' },
      template: 'MainContent',
    },
    {
      name: 'CategoryFacetPanel',
      type: 'FacetPanel',
      template: 'FacetPanelContainer',
      location: { replace: 'body.templateCollection .page-cata #sidebar' },
      ignoreFields,
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm_collections-page',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brands-page',
      template: 'facetTiles/hierarchicalContainer',
    },
    {
      name: 'SearchBox',
      location: '.header-content .searchbox',
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.mobile-layout-bar .search-button',
        class: 'search-button searchbox',
        wrapper: 'li',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'DesktopGarage',
      type: 'Garage',
      location: {
        insertAfter: '.header-content .header-middle > .phone',
        class: 'd-none d-lg-block cm_garage__desktop',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: {
        insertBefore: '.mobile-layout-bar .currency-translator',
        wrapper: 'li',
        class: 'cm_garage__mobile',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'CategoryVehicleTitle',
      type: 'VehicleWidget',
      location: 'body.templateCollection .page-cata .wrap-cata-title h2',
      template: 'fitmentSearch/categoryVehicleTitle',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertAfter: 'body.templateProduct .product-info-inner > div', class: 'mb-3' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: {
        lastChildOf: 'body.templateProduct .product-simple-tab .tab-content',
        id: 'tabs-fitment',
        class: 'tab-pane',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: { selector: '.tabs-fitment' },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm_ymm-tab',
      template: 'fitmentSearch/homeVehicleWidget',
    },
    {
      name: 'HomeTabSearchBox',
      type: 'SearchBoxDialogButton',
      location: { selector: '#cm_search-tab', class: 'cm_search-box-root__home-tab' },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
